export const getWeatherClass = (strWeather) => {
    let strClass;
    switch(strWeather.toUpperCase()) {
        default:
            strClass = 'clear';
            break;
        case 'FOGGY':
        case 'SUNNY':
        case 'WINDY':
        case 'CLOUDS':
        case 'THUNDERSTORM':
        case 'RAIN':
        case 'PARTLY-CLOUDY':
        case 'LIGHT-RAIN':
        case 'SNOW':
        case 'MIST':
        case 'DRIZZLE':
        case 'FOG':
        case 'CLEAR':
            strClass = strWeather.toLowerCase();
            break;
    }

    return strClass;
}

export const formatQueryDate = stamp => {
    //2021-10-01T09%3A20%3A00

    const date = new Date(stamp);
    const day = date.getDate().length > 1 ? date.getDate() : '0' + date.getDate();
    const month = date.getUTCMonth()+1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day}-${month}-${year}T${hours}%3A${minutes}%3A00`;
}

export const replaceSubject = subject => {
    let friendly = '';
    switch(subject) {
        default:
            friendly = subject;
            break;
        case 'Afsl.prv: Webud':
        case 'Apr:1525, 1':
            friendly = 'Svendeprøve';
            break;
        case 'Graf.tekn.':
            friendly = 'Grafisk teknik';
            break;
        case 'Webudvikler':
            friendly = 'Webudvikling';
            break;
        case 'iværk innova':
            friendly = 'Iværksætteri';
            break;
        case 'graf tekno udv':
            friendly = 'Grafisk Teknologi';
            break;
        case 'komm formid lll':
            friendly = 'Kommunikation';
            break;
        case 'a3dtypo':
        case 'dmålgruppe':
            friendly = 'Adobe After Effects';
            break;
        case 'ggrafikopgaver':
            friendly = 'Adobe Illustrator';
            break;
            case 'andre trykmetod':
            friendly = 'Andre trykmetoder';
            break;
    }
    return friendly;
}

export const replaceEducation = education => {
    let friendly = '';
    switch(education) {
        default:
            friendly = education;
            break;
        case 'Grafisk teknik.':
            friendly = 'Grafisk tekniker';
            break;
        case 'Data/komm.udd.':
            friendly = 'Webudvikler';
            break;
        case 'AMU indmeld':
            friendly = 'AMU Kursus';
            break;
    }
    return friendly;
}