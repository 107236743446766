import React, { useState, useEffect } from 'react'
import './Dish.scss';
import { fetch2api } from '../../helpers/helpers'

export const DishOfTheDay = () => {
    const [dishData, setDishData] = useState({});
    const arrDayLocal = ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"]
    const date = new Date().getDay();

    useEffect(() => {
        const getDish = async () => {
            let endpoint = 'https://infoskaerm.techcollege.dk/umbraco/api/content/getcanteenmenu/?type=json';
            const result = await fetch2api(endpoint);
            setDishData(result);
        }

        getDish();

        // Opdaterer hver time
        const interval = setInterval(() => {
            getDish();
        }, 3600000);
        return () => clearInterval(interval);
    }, []);

    return (
        <section>
            <h2>Ugens menu</h2>
            <table className="dish">
                <tbody>
                    {dishData && dishData.Days && dishData.Days.map((item, key) => {
                        //console.log(key);
                        return (
                            <tr key={key} className={arrDayLocal[date] === item.DayName ? 'active' : null}>
                                <td>{item.DayName}:</td>
                                <td>{item.Dish}</td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
        </section>
    )
}