import React from 'react';
import { createContext, useState, useEffect, useContext } from 'react'
import { fetch2api } from '../../helpers/helpers'
import { getWeatherClass } from '../../helpers/infoboards'

export const WeatherContext = createContext();

export const WeatherContextProvider = ({children}) => {
    const [weatherData, setWeatherData] = useState('')

    useEffect(() => {

        const fetchData = async () => {

            let data = {
                text: 'Cloudy',
                image: 'clouds',
                description: 'Clouds',
                temp: 0
            }

            const url = 'https://api.openweathermap.org/data/2.5/weather?q=Aalborg&appid=4d58d6f0a435bf7c5a52e2030f17682d&units=metric'
            const response = await fetch2api(url);

            //console.log(response.main.temp);

            data = {
                text: response.weather[0].main,
                image: getWeatherClass(response.weather[0].main),
                //image: getWeatherClass('Windy'),
                description: response.weather[0].description,
                temp: response.weather[0].main
            }   
            setWeatherData(data)
        }    
        fetchData();
    }, [])

    return (
        <WeatherContext.Provider value={{weatherData, setWeatherData}}>
            {children}
        </WeatherContext.Provider>
    )
}

export const useWeatherContext = () => useContext(WeatherContext);