import React from 'react';
import './Aside.scss';
import { useContext } from 'react';
import { Clock } from '../Clock/Clock';
import { DishOfTheDay } from '../DishOfTheDay/DishOfTheDay';
import { WeatherContext } from '../Weather/WeatherContextProvider'
import { Busplan } from '../Busplan/Busplan';

export const Aside = () => {
    const { weatherData } = useContext(WeatherContext)
    
    return (
        <aside className={weatherData.image}>
            <Clock />
            <DishOfTheDay />
            <Busplan />
        </aside>
    )
}