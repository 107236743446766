import './News.scss'
import React from 'react';
import { useEffect, useState } from "react"
import { fetch2api } from "../../helpers/helpers";

export const News = () => {
    const [newsData, setNewsData] = useState(null);

    const getNews = async () => {
        let endpoint = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.dr.dk%2Fnyheder%2Fservice%2Ffeeds%2Fallenyheder%23'
        let result = await fetch2api(endpoint)        
        setNewsData(result.items)
    }

    useEffect(() => {
        getNews()
    }, [])

    return (
        // eslint-disable-next-line
        <marquee scrollamount="10" behavour="">
            {newsData && newsData.map((item, key) => {
                return (
                    <React.Fragment key={key}>
                    <span>{item.title}</span>
                    <span>&#8226;</span>
                    </React.Fragment>
                )
            })}
        </marquee>
    )
}