/**
 * Nørdet Function til at fetche med
 * @param {*} url API Endpoint
 * @param {*} options Option Object
 * @returns Array
 */
export async function fetch2api(url, options = null) {
    if(!options) {
        options = {
            method: 'GET'
        }
    }
    try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result;

    }
    catch(error) {
        console.error(`Fetch2Api Fejl: ${error}`);
    }
}

/**
 * Funktion som returnerer time:minut ud fra datoformat
 * 03.11.2021 heka
 * Date hack til at sikre timezone tider
 * Funktionen tager et timestamp af api dato og beregner 
 * forskellen fra current timezone i minutter (offset)
 * Derefter trækkes offset i sekunder fra stamp og så 
 * er der taget højde for tidsforskellen 
 * @param {*} date 
 * @returns 
 */
export const date2hours = date => {
    const inputHours = JSON.stringify(new Date(date).getHours());
    const inputMinutes = JSON.stringify(new Date(date).getMinutes());
    const hours = inputHours.padStart(2, '0');
    const minutes = inputMinutes.padEnd(2, '0');
    return `${hours}:${minutes}`;
}

/**
 * Funktion til at hente dk dag
 * @param {*} intDay 
 * @returns 
 */
export const day2local = intDay => {
    let day;
    switch(intDay) {
        default:
            break;
        case 0:
            day = 'Søndag';
            break;
        case 1:
            day = 'Mandag';
            break;
        case 2:
            day = 'Tirsdag';
            break;
        case 3:
            day = 'Onsdag';
            break;
        case 4:
            day = 'Torsdag';
            break;
        case 5:
            day = 'Fredag';
            break;
        case 6:
            day = 'Lørdag';
            break;
    }

    return day;
}

/**
 * Funktion til at hente dk måned
 * @param {*} intMonth 
 * @returns 
 */
export const month2local = intMonth => {
    let month;
    switch (intMonth) {
        default:
            break;
        case 0:
            month = 'Januar';
            break;
        case 1:
            month = 'Febuar';
            break;
        case 2:
            month = 'Marts';
            break;
        case 3:
            month = 'April';
            break;
        case 4:
            month = 'Maj';
            break;
        case 5:
            month = 'Juni';
            break;
        case 6:
            month = 'Juli';
            break;
        case 7:
            month = 'August';
            break;
        case 8:
            month = 'September';
            break;
        case 9:
            month = 'Oktober';
            break;
        case 10:
            month = 'November';
            break;
        case 11:
            month = 'December';
            break;
    }   
    
    return month;
}

export const dayDateMonth2local = strDate => {
    const date = new Date(strDate);
    return `${day2local(date.getDay())} d. ${date.getDate()}. ${month2local(date.getMonth())}`;
}

export const formatTimestamp = stamp => {
    const date = new Date(stamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth()+1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day} ${month} ${year} ${hours} ${minutes}`;
}

/**
 * 
 * @param {String} date 
 * @returns 
 */
export const convert2Timezone = date => {
    const d = new Date(date);    
    const stamp = ((d.valueOf()/1000) - (d.getTimezoneOffset()*60))*1000;
    return new Date(stamp);
}

/**
 * Funktion til at sortere arrays efter en given objekt egenskab
 * @param {*} array // Array
 * @param {*} prop  // Object property name
 */
export const sortByObject = (array, prop) => {
    array.sort(function(a, b) {
        let x = a[prop].toLowerCase();
        let y = b[prop].toLowerCase();
        if(x < y) { return -1; }
        if(x > y) { return 1; }
        return 0;
     });
}

