import React from 'react';
import './App.scss';
import { WeatherContextProvider } from './components/Weather/WeatherContextProvider';
import { Aside } from './components/Aside/Aside';
import { News } from './components/News/News';
import { Activities } from './components/Activities/Activities';

function App() {
 
  return (
    <div id="container">

      <WeatherContextProvider>
          <Aside />
      </WeatherContextProvider>
      <section id="main">
        <Activities />
      </section>
      <News />
    </div>
  );
}

export default App;