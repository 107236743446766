import './Activities.scss';
import React, { useState, useEffect } from 'react';
import { fetch2api, date2hours, dayDateMonth2local, convert2Timezone } from '../../helpers/helpers';
import { replaceEducation, replaceSubject  } from '../../helpers/infoboards';
import Tim from '../../assets/images/widgets/tim.jpg'

export const Activities = () => {
    const [activityData, setActivityData] = useState([]);

    /**
     * Asynkron funktion til at hente aktiviteter med
     * Data fra API hentes og gemmes i local storage som opdateres 
     * hver dag
     */
    const getActivities = async () => {
        // Nuværende tid i sekunder
        const curday_stamp = Math.round(Date.now() / 1000);
        // Morgendagen i sekunder
        const nextday_stamp = new Date().setHours(0, 0, 0, 0) / 1000 + 86400;
        
        // Tre dage fremme i sekunder (så fanger vi mandagens aktiviteter om fredagen)
        //const three_days_ahead  = (new Date().setHours(0, 0, 0, 0) / 1000) + 258000;    


        // Henter gemte data fra localstore
        let data = JSON.parse(localStorage.getItem('activities'));
        //data = '';

        const checkhour = new Date(curday_stamp*1000).getHours();

        const arr_valid_educations = [
            'AMU indmeld',
            'Brobyg teknisk',
            'Data/komm.udd.',
            'Grafisk Tekniker', 
            'Grafisk teknik.', 
            'Mediegrafiker', 
            'Webudvikler'
        ]

        // Hvis data er tom eller uddateret hentes friske data fra API'et
        if(!data || checkhour === 6) {
            let result;
            let endpoint;
            data = [];

            // Kald til alm uddannelser
            endpoint = `https://iws.itcn.dk/techcollege/schedules?departmentCode=smed&$orderby=StartDate,Room`;
            result = await fetch2api(endpoint);
            data = result.value;

            // Kald til AMU + Brobyg
            endpoint = `https://iws.itcn.dk/techcollege/schedules?departmentCode=med&$orderby=StartDate,Room`;
            result = await fetch2api(endpoint);
            data = data.concat(result.value);            

            data.sort((a, b) => a.StartDate.localeCompare(b.StartDate));

            // Gemmer array i local storage
            localStorage.setItem('activities', JSON.stringify(data));
            localStorage.setItem('last_update', new Date(curday_stamp*1000));
        }

        // Fixer timestamp i array
        for (let item of data) {
            /*
            if(item.Team === 'amea080921') {
                console.log(item);
            }
            */
            item.StartDate = convert2Timezone(item.StartDate);
            if(arr_valid_educations.includes(item.Education)) { 
                item.Stamp = (Date.parse(item.StartDate) / 1000);
                item.Subject = replaceSubject(item.Subject);
                item.Education = replaceEducation(item.Education);
                //console.log('Dat:' + item.Stamp + "\nCur:" + curday_stamp);
                //console.log(date2hours(item.StartDate));
            } else {
                console.log(item.Education);
            }
        }

        // Opretter tomt array
        const classes = [];

        // Pusher alle data der matcher dags dato
        classes.push(...data.filter(elements => elements.Stamp > curday_stamp && elements.Stamp < nextday_stamp));

        // Hvis array er mindre end 14
        if (classes.length < 14) {
            // Henter data der er større end i dag
            const nextday_data = data.filter(elements => elements.Stamp >= nextday_stamp);

            if (nextday_data.length) {
                // Pusher index med næste dag tekst
                classes.push({ day: dayDateMonth2local(nextday_data[0].StartDate) })
                // Pusher index med næste dag data
                classes.push(...data.filter(elements => elements.Stamp >= nextday_stamp));
            }
        }
        
        // Opdaterer state var
        setActivityData(classes.slice(0, 14));
    }

    /**
     * Funktion til at udskrive række med aktivitetsdata
     * @param {Object} obj Data Object med aktivitet
     * @param {Number} key Array Index
     * @returns html string
     */
    const setActivityRow = (obj, key) => {
        return (
            <tr key={key}>
                <td>{date2hours(obj.StartDate)}</td>
                <td>{obj.Subject}</td>
                <td>{obj.Education}</td>
                <td>{obj.Team}</td>
                <td className="room">{obj.Room}</td>
            </tr>
        )
    }

    /**
     * Funktion til at udskrive række med dag
     * @param {String} strDay Dag, dato og måned
     * @param {Number} key Array Index
     * @returns html string
     */
    const setNewDayRow = (strDay, key) => {
        return (
            <tr key={key}>
                <td colSpan="5">
                    <h4>{strDay}</h4>
                </td>
            </tr>
        )
    }

    useEffect(() => {
        getActivities();
        // Set Interval til hver 10. minut
        setInterval(() => {
            getActivities();
        }, 600000)
        // eslint-disable-next-line
    }, []);

    return (
        <>
        <table cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th>Kl.</th>
                    <th>Fag</th>
                    <th>Uddannelse</th>
                    <th>Hold</th>
                    <th>Lokale</th>
                </tr>
            </thead>
            <tbody>
                {activityData && activityData.map((item, key) => {
                    return (item.Team) ? (
                        setActivityRow(item, key)
                    ) : (
                        setNewDayRow(item.day, key)
                    )
                })
                }
            </tbody>
        </table>
        
        </>
    )
}