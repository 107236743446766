import './Clock.scss';
import React, { useEffect, useState } from "react";

export const Clock = () => {
    const [date, setDate] = useState(new window.Date());

    useEffect(() => {
        setInterval(() => {
            setDate(new window.Date());
        }, 1000)
    }, [])

    return (
        <section className="clock">
            <p>
                {date && date.toLocaleString([], {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                })}
            </p>
            <h1>
                {date && date.toLocaleString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                }).replace(".", ":")}
            </h1>
        </section>
    )
}