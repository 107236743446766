import './Busplan.scss';
import React from 'react';
import { useState, useEffect } from "react"
import { fetch2api } from "../../helpers/helpers";
const interval = 1;

export const Busplan = () => {
    const [busData, setBusData] = useState([]);

    const getBusData = async () => {
        // Kalder data
        const endpoint = 'https://xmlopen.rejseplanen.dk/bin/rest.exe/multiDepartureBoard?id1=851400602&id2=851973402&rttime&format=json&useBus=1';
        const data = await fetch2api(endpoint);

        // Sætter variabel til array med fem busafgange 
        let array_departures = data.MultiDepartureBoard.Departure.slice(0, 5);

        // Sætter dags dato med JS Date
        const current_date = new Date();
        const current_stamp = Math.round(current_date.getTime() / 1000);

        // Mapper spliced_departures
        array_departures.map((dep, key) => {

            // Splitter date og time til array: 
            // 0 = Dato, 1 = Måned, 2 = År, 3 = Time, 4 = Minut
            const arr_date_spl = dep.date.split('.').concat(dep.time.split(':'));

            // Fikser årstal til fire cifre
            arr_date_spl[2] = arr_date_spl[2].padStart(4, '20');

            // Formaterer date så den passer med et JS Date format
            const date_formatted = `${arr_date_spl[2]}-${arr_date_spl[1]}-${arr_date_spl[0]} ${arr_date_spl[3]}:${arr_date_spl[4]}:00`;

            // Henter ind som JS Date og konverterer til sekunder
            const dep_stamp = new Date(date_formatted).getTime();
            const dep_stamp_secs = (dep_stamp/1000);
            console.log(new Date(dep_stamp).getMinutes());

            // Beregner antal minutter fra nu og til pågældende afgang
            const minutes_left = Math.ceil((dep_stamp_secs - current_stamp) / 60);

            // Tjek resultat
            // console.log(`${dep.time} => ${diff_seconds}`);

            // OPdaterer array med diff
            array_departures[key].minutes_left = minutes_left;


            return (
                array_departures
            )
        })

        setBusData(array_departures)
    }

    useEffect(() => {
        getBusData();
        setTimeout(() => getBusData(), interval * 1000 * 60);
    }, [])

    return (
        <section>
            <h2>Bustider</h2>
            <ul className="busplan">
                {busData &&
                    busData.map((item, key) => (
                        <li key={key}>
                            <p>{item.line}</p>
                            <p>{item.direction}</p>
                            <p>{item.minutes_left}</p>
                        </li>
                    ))}
            </ul>
        </section>)
}